import Navbar from "../../compose/Navbar/Navbar";
import Footer from "../../compose/Footer/Footer"
import Gallery from "../../compose/Gallery/Gallery";
import Introduction from "./sections/Introduction";
import Testimonials from "compose/Testimonials/Testimonials";

import { Helmet } from 'react-helmet';

const GalleryPage = () => {
    return (
        <>
            <Helmet>
                <title>Cabinets Gallery - RTA Cabinets Tailored to Your Needs | River City Cabinets</title>
                <meta name="description" content="Explore stunning cabinet designs by River City Cabinets. Our RTA cabinets are tailored to your needs. Visit us now for inspiration and more."></meta>
                <link rel="canonical" href="https://www.rivercitycabinetsidaho.com/gallery/"></link>
            </Helmet>
            <Navbar />
            <Introduction />
            <Gallery title="Which one do you like the most?" background="var(--bg-darker)" />
            <Testimonials />
            <Footer />
        </>
    )
}

export default GalleryPage;