import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

const MetaTags = ({ title, description, canonical, thumbnail }) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <link rel="canonical" href={canonical}></link>
            <meta property="og:image" content={thumbnail} />
        </Helmet>
    );
};

MetaTags.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    canonical: PropTypes.string.isRequired,
    thumbnail: PropTypes.string.isRequired
};

export default MetaTags;