import './App.css';
import Landing from './pages/landing/Landing';
import PageNotFound from 'pages/404';

import { useEffect } from 'react';

import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

import routes from './routes';

import TagManager from "react-gtm-module";

function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // Comment when developing - Uncomment to deploy to production
  useEffect(() => {
    TagManager.initialize({ gtmId: "GTM-PMGR4JM"});
  }, []);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <div className="App">
      <Routes>
        {getRoutes(routes)}
        <Route path="/" element={<Landing />} />
        <Route path="/404" element={<PageNotFound />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    </div>
  );
}

export default App;
