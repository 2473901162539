import "./Services.css";

import Navbar from '../../compose/Navbar/Navbar';
import Footer from '../../compose/Footer/Footer'
import Thumbnail from "../../components/Gallery/Thumbnail";
import Testimonials from "compose/Testimonials/Testimonials";

import Image from "assets/imgs/services/bathroompage.webp";

import { useNavigate } from 'react-router-dom';

import { Helmet } from 'react-helmet';

const Bathroom = () => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate("/contact");
    }

    return (
        <>
            <Helmet>
                <title>Bathroom Cabinets | Modern Storage Solutions | River City Cabinets</title>
                <meta name="description" content="Contact us today to get a consultation with our RTA cabinet professionals in Nampa and Boise Area. Cabinets by design that meet your specific needs."></meta>
                <link rel="canonical" href="https://www.rivercitycabinetsidaho.com/services/bathroom/"></link>
            </Helmet>
            <Navbar />
            <section className="services-pages">
                <div className="title">
                    <h1>Bathroom cabinets by design with modern storage solutions</h1>
                    <hr />
                </div>
                <div className="item">
                    <Thumbnail item="bathroom" cover noCursor />
                    <div className="info">
                        <h2>Modern bathroom storage cabinet</h2>
                        <p>River City Cabinets is your premier choice for bathroom cabinets. Our expert craftsmen have years of experience creating beautiful and functional cabinets for both residential and commercial clients.</p>

                        <div style={{display: "flex", justifyContent: "center"}}>
                            <img src={Image} alt="Kitchen cabinet professional working." style={{width: "100%", maxWidth: "640px"}}/>
                        </div>

                        <p>
                            We offer a wide range of options for your bathroom cabinets, with modern storage solutions, and ready-to-assemble (RTA) cabinets. As a cabinet installer, we work closely with you to create cabinets by design that meet your specific needs. Trust River City Cabinets for all your bathroom cabinet needs.
                        </p>

                        <div className="cta">
                            <button className="primary-button" onClick={handleButtonClick}>Contact Now</button>
                        </div>
                    </div>
                </div>
            </section>
            <Testimonials />
            <Footer />
        </>
    )
}

export default Bathroom;