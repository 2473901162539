import "./Services.css";

import Navbar from '../../compose/Navbar/Navbar';
import Footer from '../../compose/Footer/Footer'
import Thumbnail from "../../components/Gallery/Thumbnail";
import Services from "../../compose/Services/Services";

import Image from "assets/imgs/services/servicespage.webp";

import { useNavigate } from 'react-router-dom';

import { Helmet } from 'react-helmet';

const ServicesPage = () => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate("/contact");
    }

    return (
        <>
            <Helmet>
                <title>RTA Cabinets & Flooring in Nampa, Boise Area | River City Cabinets</title>
                <meta name="description" content="Get a free quote today with a professional cabinet designer and cabinet installer in the Nampa and Boise area."></meta>
                <link rel="canonical" href="https://www.rivercitycabinetsidaho.com/services/"></link>
            </Helmet>
            <Navbar />
            <section className="services-pages">
                <div className="title">
                    <h1>RTA Cabinets & Flooring in Nampa, Boise Area</h1>
                    <hr />
                    <p>Cabinet Designer, Installer and Flooring Remodel in Nampa and Boise Area.</p>
                </div>
                <div className="item">
                    <Thumbnail item="services" cover noCursor />
                    <div className="info">
                        <h2>Residential and commercial cabinets</h2>
                        <p>
                            River City Cabinets is your go-to cabinet designer and installer near you in the Nampa and Boise Metropolitan areas. We specialize in residential and commercial cabinets, as well as LVP flooring.
                        </p>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <img src={Image} alt="Kitchen with beautiful cabinets in blue shaker color." style={{width: "100%", maxWidth: "640px"}}/>
                        </div>
                        <p>
                            Our team of experienced professionals is dedicated to creating cabinets by design that fit your specific needs and preferences, using high-quality materials and superior craftsmanship. Whether you need new cabinets for your kitchen, bathroom, or office, we've got you covered. We also offer commercial cabinets for businesses looking to upgrade their space. 
                        </p>
                        <p>
                            Let River City Cabinets bring your vision to life with our exceptional service and attention to detail.
                        </p>

                        <div className="cta">
                            <button className="primary-button" onClick={handleButtonClick}>Get Quote</button>
                        </div>
                    </div>
                </div>
            </section>
            <Services title="Cabinets by design and flooring" kitchenTitle="Kitchen Cabinets" bathroomTitle="Bathroom Cabinets" countertopsTitle="Countertops" hoodsTitle="Hoods" flooringTitle="Flooring" />
            <Footer />
        </>
    )
}

export default ServicesPage;