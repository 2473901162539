import './Services.css';

import Thumbnail from '../../components/Gallery/Thumbnail';

import { Link, useNavigate } from 'react-router-dom';

// import Swiper core and required modules
// import { Pagination } from 'swiper';

// import Swiper core and required modules
// import { Navigation, Autoplay } from 'swiper';

// Import Swiper React components
// import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
// import 'swiper/css/pagination';

const Services = props => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate("/contact");
    }

    return (
        <section className="services" id="services" style={{background: `${props.background}`}}>
            <div className="title">
                <h2>{props.title}</h2>
                <hr/>
                <p>Make your home or business stand out with quality RTA cabinets.</p>
            </div>
            {/* <div className="items desktop">
                <div className="item">
                    <Link to={"/services/kitchen"}>
                        <Thumbnail item="kitchen" cover />
                    </Link>
                    <div className="info">
                        <Link to={"/services/kitchen"}><h3>Kitchen Cabinets By Design</h3></Link>
                        <p>When you hire River City Cabinets you get expert kitchen cabinet installation and designers who can help you select the perfect kitchen cabinets for your kitchen. You will find a wide variety of materials, styles and stains to choose from.</p>
                        <Link to={"/services/kitchen"}>See more...</Link>
                    </div>
                </div>
                <hr/>
                <div className="item">
                    <div className="info">
                        <Link to={"/services/bathroom"}><h3>Bathroom Cabinets By Design</h3></Link>
                        <p>Trust the cabinet design specialists at River City Cabinets to handle your bathroom cabinet design and installation serving all of the Treasure Valley. Remodeling your bathroom cabinets increases the value of your home while also providing your bathroom with an aesthetic appeal that you will love.</p>
                        <Link to={"/services/bathroom"}>See more...</Link>
                    </div>
                    <Link to={"/services/bathroom"}>
                        <Thumbnail item="bathroom" cover />
                    </Link>
                </div>
                <hr/>
                <div className="item">
                    <Link to={"/services/flooring"}>
                        <Thumbnail item="flooring" cover />
                    </Link>
                    <div className="info">
                        <Link to={"/services/flooring"}><h3>Flooring</h3></Link>
                        <p>River City Cabinets are skilled in all forms of installation necessary to complete your flooring project. Depending upon the type of material you choose for your floors, there are several installation methods that can be used.</p>
                        <Link to={"/services/flooring"}>See more...</Link>
                    </div>
                </div>
            </div> */}
            {/* <div className="items mobile">
                <Swiper
                    // install Swiper modules
                    modules={[ Navigation ]}
                    slidesPerView={1}
                    // pagination={{
                    //     type: "fraction",
                    // }}
                    spaceBetween={30}
                    navigation
                    // autoplay={{
                    //     disableOnInteraction: true
                    // }}
                    className="servicesSwiper"
                >
                    <SwiperSlide>
                        <div className="item">
                            <div className="image kitchen">
                                <div className="legend">
                                    <div className="icon">
                                        <img src={KitchenIcon} alt="kitchen icon" />
                                    </div>
                                    <div className="description">
                                        <p>Kitchen</p>
                                    </div>
                                </div>
                            </div>
                            <div className="info">
                                <h3>Kitchen Cabinets</h3>
                                <p>When you hire River City Cabinets you get expert kitchen cabinet installation and designers who can help you select the perfect kitchen cabinets for your kitchen. You will find a wide variety of materials, styles and stains to choose from.</p>
                                <a href="/services/kitchen.html">See more...</a>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="item">
                            <div className="image bathroom">
                                <div className="legend">
                                    <div className="icon">
                                        <img src={BathroomIcon} alt="bathroom icon" />
                                    </div>
                                    <div className="description">
                                        <p>Bathroom</p>
                                    </div>
                                </div>
                            </div>
                            <div className="info">
                                <h3>Bathroom Cabinets</h3>
                                <p>Trust the cabinet design specialists at River City Cabinets to handle your bathroom cabinet design and installation serving all of the Treasure Valley. Remodeling your bathroom cabinets increases the value of your home while also providing your bathroom with an aesthetic appeal that you will love.</p>
                                <a href="/services/bathroom.html">See more...</a>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="item">
                            <div className="image flooring">
                                <div className="legend">
                                    <div className="icon">
                                        <img src={FlooringIcon} alt="kitchen icon" />
                                    </div>
                                    <div className="description">
                                        <p>Flooring</p>
                                    </div>
                                </div>
                            </div>
                            <div className="info">
                                <h3>Flooring</h3>
                                <p>River City Cabinets are skilled in all forms of installation necessary to complete your flooring project. Depending upon the type of material you choose for your floors, there are several installation methods that can be used.</p>
                                <a href="/services/flooring.html">See more...</a>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div> */}
            <div className="items mobile">
                <div className="item">
                    <Link to={"/services/kitchen"}>
                        <Thumbnail item="kitchen" cover />
                    </Link>
                    <div className="info">
                        <Link to={"/services/kitchen"}><h3>{props.kitchenTitle}</h3></Link>
                        <p>We offer high-quality, ready-to-assemble cabinets at unbeatable prices to help you create the kitchen of your dreams quickly and professionally.</p>
                        <Link to={"/services/kitchen"}>See more...</Link>
                    </div>
                </div>
                <div className="item">
                    <Link to={"/services/bathroom"}>
                        <Thumbnail item="bathroom" cover />
                    </Link>
                    <div className="info">
                        <Link to={"/services/bathroom"}><h3>{props.bathroomTitle}</h3></Link>
                        <p>River City Cabinets offers premium bathroom cabinets with quick delivery, luxurious designs, and competitive prices to elevate your space.</p>
                        <Link to={"/services/bathroom"}>See more...</Link>
                    </div>
                </div>
                <div className="item">
                    <Link to={"/services/countertops"}>
                        <Thumbnail item="countertops" cover />
                    </Link>
                    <div className="info">
                        <Link to={"/services/countertops"}><h3>{props.countertopsTitle}</h3></Link>
                        <p>Upgrade your kitchen or bathroom with durable, stylish solid surface countertops.</p>
                        <Link to={"/services/countertops"}>See more...</Link>
                    </div>
                </div>
                <div className="item">
                    <Link to={"/services/kitchen-hoods"}>
                        <Thumbnail item="hoods" cover />
                    </Link>
                    <div className="info">
                        <Link to={"/services/kitchen-hoods"}><h3>{props.hoodsTitle}</h3></Link>
                        <p>Elevate your kitchen with stylish hoods. Explore sleek designs & powerful ventilation for premium cooking experiences.</p>
                        <Link to={"/services/kitchen-hoods"}>See more...</Link>
                    </div>
                </div>
                <div className="item">
                    <Link to={"/services/flooring"}>
                        <Thumbnail item="flooring" cover />
                    </Link>
                    <div className="info">
                        <Link to={"/services/flooring"}><h3>{props.flooringTitle}</h3></Link>
                        <p>Our friendly and professional installation team provides exemplary service for both residential and commercial flooring projects.</p>
                        <Link to={"/services/flooring"}>See more...</Link>
                    </div>
                </div>
            </div>
            <button className="primary-button" onClick={handleButtonClick}>Contact Now</button>
        </section>
    )
}

export default Services;