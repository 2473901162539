import './Testimonials.css';
import { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

import reviews from './data/data';

// import Swiper core and required modules
import { Navigation, Autoplay } from 'swiper';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';

const Testimonials = () => {
    const [isTruncated, setIsTruncated] = useState(true);

    const toggleText = () => {
        setIsTruncated(!isTruncated);
    }

    return (
        <section className="testimonials" id="testimonials">
            <div className="title">
                <h2>What our customers say about us</h2>
                <hr />
                <p>Experience our quality craftsmanship and outstanding customer service.</p>
            </div>
            <div className="reviews">
                <Swiper
                    modules={[ Navigation, Autoplay ]}
                    spaceBetween={30}
                    slidesPerView={1}
                    navigation
                    autoplay={{
                        disableOnInteraction: true
                    }}
                    onSlideChange={() => setIsTruncated(true)}
                    className="swiper reviews-Swiper"
                >
                    <div className="swiper-wrapper" id="reviews">
                        {reviews.map((item, index) => (
                            <SwiperSlide key={index}>
                                <div className="review" id={`review-${index+1}`}>
                                    {/* <img src={require(`../../assets/imgs/testimonials/${index+1}.webp`)} alt={item.author_name} /> */}
                                    {/* <img src={item.profile_photo_url} alt="Google Profile" /> */}
                                    <p className="name">{item.author_name}</p>
                                    {isTruncated ? (
                                        <>
                                            {item.text.length < 500 ? (
                                                <>
                                                    <p className="text">{item.text}</p>
                                                </>
                                            ) : (
                                                <>
                                                    <p className="text">{item.text.substring(0, 500) + '...'}</p>
                                                    <p className="see-more" onClick={toggleText}>Read More...</p>
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {item.text.length < 500 ? (
                                                <>
                                                    <p className="text">{item.text}</p>
                                                </>
                                            ) : (
                                                <>
                                                    <p className="text">{item.text}</p>
                                                    <p className="see-more" onClick={toggleText}>Show Less</p>
                                                </>
                                            )}
                                        </>
                                    )}
                                </div>
                            </SwiperSlide>
                        ))}
                    </div>
                </Swiper>
                <div className="overall">
                    <p>We are 5<FontAwesomeIcon icon={icon({name: 'star', style: 'solid'})} bounce className="fa fa-star checked"/> on <span className="google" style={{color: '#4285F4'}}>G</span><span className="google" style={{color: '#DB4437'}}>o</span><span className="google" style={{color: '#F4B400'}}>o</span><span className="google" style={{color: '#4285F4'}}>g</span><span className="google" style={{color: '#0F9D58'}}>l</span><span className="google" style={{color: '#DB4437'}}>e</span>!</p>
                    <div className="link">
                        <a href="https://g.page/rivercitycabinetsidaho?share" target="_blank" rel="noreferrer">See Google Review</a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Testimonials;