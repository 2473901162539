import "./Articles.css";

import Card1 from "components/Blog/cards/Card1";
import Cover from "../components/Cover";

import { createClient } from "contentful";

import { useState, useEffect } from "react";

const Articles = () => {

    const [blogPosts, setBlogPosts] = useState([]);

    useEffect(() => {
        const getAllEntries = async () => {
            try {
                await createClient({ space: process.env.REACT_APP_CONTENTFUL_SPACE_ID, accessToken: process.env.REACT_APP_CONTENTFUL_API_KEY })
                    .getEntries({
                        content_type: "blog",
                        select: "fields"
                    })
                    .then((entries) => {
                        setBlogPosts(entries);
                    })
            } catch(error) {
                console.log(error);
            }
        }
        getAllEntries();
    }, [])

    return (
        <>
            <section className="articles">
                <div className="title">
                    <h2>Our recent writings</h2>
                    <hr />
                    <p>Stay up-to-date with the latest from River City Cabinets - Our recent writings on cabinets and flooring.</p>
                </div>
                <div className="container">
                    {blogPosts?.items?.map((post) => {
                        return (
                            <Card1 
                                title={post.fields.title}
                                text={post.fields.summary}
                                route={`/blog/${post.fields.url}`}
                                key={post.sys.id}>
                                    <Cover src={post.fields.image.fields.file.url} alt={post.fields.image.fields.description} />
                            </Card1>
                        )
                    })}
                </div>
            </section>
        </>
    )
}

export default Articles;