import "./Card1.css";

import { Link } from "react-router-dom";

const Card1 = (props) => {
    return (
        <>
            <div className="card1">
                <div className="image-container">
                    <Link to={props.route}>
                        {props.children}
                    </Link>
                </div>
                <div className="text">
                    <Link to={props.route}>
                        <h3>{props.title}</h3>
                    </Link>
                    <p>{props.text}</p>
                    <Link 
                        to={props.route} 
                        style={{
                            fontWeight: 300, 
                            fontSize: "16px", 
                            lineHeight: "26px", 
                            color: "var(--primary-color)"
                        }}>
                            Read more
                    </Link>
                </div>
            </div>
        </>
    )
}

export default Card1;