import "./Introduction.css";

import Thumbnail from "../../../components/Gallery/Thumbnail";

import { useNavigate } from 'react-router-dom';

const Introduction = () => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate("/contact");
    }

    return (
        <>
            <section className="gallery-intro">
                <div className="title">
                    <h1>Cabinet & Countertops Gallery</h1>
                    <hr />
                    <p>Real projects from our satisfied customers. Contact us to learn more about our services and how we can help make your dream cabinets a reality.</p>
                </div>
                <div className="item">
                    <Thumbnail item="gallery" cover noCursor />
                    <div className="info">
                        <h2>Get Inspired by Our Customers' Beautiful Cabinet Projects</h2>
                        <p>
                            Welcome to River City Cabinets gallery! Our gallery features real projects from our satisfied customers, showcasing the stunning results of our cabinet design and installation services.
                        </p>
                        <p>
                            From kitchen and bathroom cabinets to entertainment centers, our team has helped bring our customers' visions to life. Browse our gallery to get inspired for your own home renovation project.
                        </p>
                        <p>
                            Contact us today to learn more about our services and how we can help make your dream cabinets a reality.
                        </p>
                        <div className="cta">
                            <button className="primary-button" onClick={handleButtonClick}>Get Quote</button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Introduction;