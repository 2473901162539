import "./index.css";
import Navbar from "compose/Navbar/Navbar";
import Footer from "compose/Footer/Footer";
import Heading from "pages/blog/article/sections/Heading";

import { createClient } from "contentful";

import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import ReactMarkdown from "react-markdown";

import MetaTags from "components/Util/MetaTags";

import { useNavigate, useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

import { EmailShareButton, EmailIcon, FacebookShareButton, FacebookIcon, LinkedinShareButton, LinkedinIcon, RedditShareButton, RedditIcon, TelegramShareButton, TelegramIcon, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon } from "react-share";

const Article = () => {
    const currentPageUrl = useLocation();

    // const [singleBlogPost, setSingleBlogPost] = useState([]);
    const [singleBlogPost, setSingleBlogPost] = useState();
    const [loadingPost, setLoadingPost] = useState(true);

    const { id } = useParams();

    const navigate = useNavigate();

    const handleGoBackButton = () => {
        navigate("/blog");
    }

    const handleContactButton = () => {
        navigate("/contact");
    }

    useEffect(() => {
        const getEntryById = async () => {
            try {
                createClient({ space: process.env.REACT_APP_CONTENTFUL_SPACE_ID, accessToken: process.env.REACT_APP_CONTENTFUL_API_KEY })
                    .getEntries({
                        content_type: "blog",
                        "fields.url": id
                    })
                    .then((entry) => {
                        if (entry.items.length > 0) {
                            const filteredEntry = entry.items.map(eachEntry => {
                                return eachEntry.fields;
                            })

                            setSingleBlogPost(filteredEntry[0]);
                            setLoadingPost(false);
                        } 
                        else {
                            setLoadingPost(false);
                            navigate("/blog/404");
                        }
                    })
            } catch(error) {
                setLoadingPost(false);
                console.log(error);
            }
        }
        getEntryById();
    }, [id, navigate])

    return (
        <>
            <Navbar />
            <Heading />
            { singleBlogPost ? 
                <>          
                    <MetaTags 
                        title={singleBlogPost.title + " | River City Cabinets"} 
                        description={singleBlogPost.metaDescription}
                        canonical={`https://www.rivercitycabinetsidaho.com/blog/${singleBlogPost.url}/`}
                        thumbnail={"https:" + singleBlogPost.image.fields.file.url}
                    />          
                    <div className="article">
                        <div className="content">
                            <div className="author">
                                <img src={"https:" + singleBlogPost.author.fields.image.fields.file.url} alt={singleBlogPost.author.fields.image.fields.description}></img>
                                <div className="author-text">
                                    <p>{singleBlogPost.createdDate === undefined ?
                                        "loading"
                                    :
                                    new Intl.DateTimeFormat('en-GB', {
                                        month: 'long',
                                        day: '2-digit',
                                        year: 'numeric',
                                    }).format(new Date(singleBlogPost.createdDate))}</p>
                                    <p><b>- {singleBlogPost.author.fields.name}</b> in <i>River City Cabinets</i></p>
                                </div>
                            </div>
                            <div className="content-text">
                                <h1>{singleBlogPost.title}</h1>
                                <ReactMarkdown children={singleBlogPost.content} />
                            </div>
                            <div className="social-share">
                                <div className="text">
                                    <p>Did you find our blog post absolutely inspiring? If so, we encourage you to <b>spread the word and share</b> this valuable information <b>with your friends, family, and colleagues</b>.</p>
                                </div>
                                <div className="buttons">
                                    <EmailShareButton
                                        subject={singleBlogPost.title}
                                        body="Check it out this amazing article at River City Cabinets:"
                                        url={`https://www.rivercitycabinetsidaho.com${currentPageUrl.pathname}`}
                                    >
                                        <EmailIcon size="48" />
                                    </EmailShareButton>
                                    <FacebookShareButton 
                                        url={`https://www.rivercitycabinetsidaho.com${currentPageUrl.pathname}`}
                                        quote="Check it out this amazing article at River City Cabinets"
                                    >
                                        <FacebookIcon size="48" />
                                    </FacebookShareButton>
                                    <LinkedinShareButton 
                                        url={`https://www.rivercitycabinetsidaho.com${currentPageUrl.pathname}`}
                                        title={singleBlogPost.title}
                                        summary={singleBlogPost.metaDescription}
                                        source={`https://www.rivercitycabinetsidaho.com${currentPageUrl.pathname}`}
                                    >
                                        <LinkedinIcon size="48" />
                                    </LinkedinShareButton>
                                    <RedditShareButton 
                                        url={`https://www.rivercitycabinetsidaho.com${currentPageUrl.pathname}`}
                                        title={singleBlogPost.title}
                                    >
                                        <RedditIcon size="48" />
                                    </RedditShareButton>
                                    <TelegramShareButton 
                                        url={`https://www.rivercitycabinetsidaho.com${currentPageUrl.pathname}`}
                                        title={singleBlogPost.title}
                                    >
                                        <TelegramIcon size="48" />
                                    </TelegramShareButton>
                                    <TwitterShareButton 
                                        url={`https://www.rivercitycabinetsidaho.com${currentPageUrl.pathname}`}
                                        title={singleBlogPost.title}
                                    >
                                        <TwitterIcon size="48" />
                                    </TwitterShareButton>
                                    <WhatsappShareButton 
                                        url={`https://www.rivercitycabinetsidaho.com${currentPageUrl.pathname}`}
                                        title={singleBlogPost.title}
                                    >
                                        <WhatsappIcon size="48" />
                                    </WhatsappShareButton>
                                </div>
                            </div>
                            <div className="cta-contact">
                                <div className="text">
                                    <p><b>Ready to take the next step towards your dream space? Let's make it happen!</b> 🏡</p>
                                    <p>For outstanding cabinets, convenient RTA solutions or remarkable flooring services, look no further than River City Cabinets. Our team of experts is ready to help you bring your vision to life. Fill out our contact form with project details, preferences and specific requirements. We'll promptly review your information, offer personalized recommendations and collaborate with you until your space is transformed. Contact us now for exceptional customer service and results you'll love.</p>
                                </div>
                                <button className="primary-button" onClick={handleContactButton}>Contact Now</button>
                            </div>
                        </div>
                    </div>
                </>
                :
                <>
                    <div className="article">
                        <div className="content">
                            <div className="content-text">
                                {loadingPost ? 
                                    <>
                                        <div className="loading">
                                            <p>Loading</p>
                                            <FontAwesomeIcon icon={icon({name: 'spinner', style: 'solid'})} size="2x" spin style={{marginBottom: "32px", color:"var(--primary-dark-color)"}} />
                                        </div>
                                    </>
                                    :
                                    <>
                                        <p>404. Sorry, the Blog page you're trying to find does not exist.</p>
                                        <p>If you need help trying to find something, please contact us.</p>
                                        <button className="primary-button" onClick={handleGoBackButton} >Go Back</button>
                                    </>
                                }   
                            </div>
                        </div>
                    </div>
                </>
            }
            <Footer />
        </>
    )
}

export default Article;