import "./FileInput.css";

import { useState } from "react";

const FileInput = ({ onChange, errorState }) => {
    const [errorMsg, setErrorMsg] = useState("");

    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        const maxSize = 25* 1024 * 1024; // Maximum allowed file size in bytes (here 25 MB)
        if (file && file.size > maxSize) {
            setErrorMsg(`File size should not exceed ${maxSize / 1024 / 1024} MB`);
            errorState(true);
        } else {
            errorState(false);
            setErrorMsg("");
            onChange(event.target.files);
        }
    };

    return (
        <div className="FileInput">
            <input type="file" multiple onChange={handleFileSelect} />
            {errorMsg && <p className="error-msg">{errorMsg}</p>}
        </div>
    )
}

export default FileInput;