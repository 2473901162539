import "./CabinetStyles.css";

import Navbar from '../../compose/Navbar/Navbar';
import Footer from '../../compose/Footer/Footer'
import Thumbnail from "../../components/Gallery/Thumbnail";

import { useNavigate } from 'react-router-dom';

import { Helmet } from 'react-helmet';

const EuroBoxPage = () => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate("/contact");
    }

    return (
        <>
            <Helmet>
                <title>Frameless Euro Cabinets | River City Cabinets</title>
                <meta name="description" content="Get inspired with our frameless / euro cabinets available for your dream project!"></meta>
                <link rel="canonical" href="https://www.rivercitycabinetsidaho.com/frameless-cabinets/"></link>
            </Helmet>
            <Navbar />
            <section className="face-framed-gallery">
                <div className="title">
                    <h1>Frameless Euro Cabinets</h1>
                    <hr />
                    <p>All styles of our frameless euro cabinets are available to help you build your dream ambiance.</p>
                </div>
                <div className="content">
                    <div className="category">
                        <div className="category-name">
                            <h2>Frameless Collection</h2>
                        </div>
                        <div className="items">
                            <Thumbnail item='dark-grain' lightbox={true} />
                            <Thumbnail item='dark-grey-gloss' lightbox={true} />
                            <Thumbnail item='glossy-white' lightbox={true} />
                            <Thumbnail item='light-grey' lightbox={true} />
                            <Thumbnail item='white-oak' lightbox={true} />
                        </div>
                    </div>
                </div>
                <div className="cta">
                    <button className="primary-button" onClick={handleButtonClick}>Contact Now</button>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default EuroBoxPage;