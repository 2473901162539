import './Landing.css';
import Navbar from '../../compose/Navbar/Navbar'
import Banner from '../../compose/Banner/Banner'
import Services from '../../compose/Services/Services'
// import Gallery from '../../compose/Gallery/Gallery'
import Testimonials from '../../compose/Testimonials/Testimonials'
// import Contact from '../../compose/Contact/Contact'
import Footer from '../../compose/Footer/Footer'
import Thumbnail from 'components/Gallery/Thumbnail';

import { Link } from 'react-router-dom';

import { Helmet } from 'react-helmet';

const Landing = () => {
    return (
        <div>
            <Helmet>
                <title>River City Cabinets | Cabinets in Nampa & Boise Area</title>
                <meta name="description" content="Explore our RTA Kitchen and Bathroom high end Cabinets at unbeatable prices. The perfect choice for all of your needs."></meta>
                <link rel="canonical" href="https://www.rivercitycabinetsidaho.com"></link>
                <meta property="og:image" content="https://images.ctfassets.net/uppe1mijrrvp/tTXbZhuJxZLK8oGev1ECe/b9f0596944763cd0c1e6aacf2227e785/22.webp" data-react-helmet="true"></meta>
            </Helmet>
            <Navbar />
            <Banner />
            {/* <Gallery background="var(--bg-lighter)" title="Cabinet gallery" /> */}
            <Services title="Services" kitchenTitle="RTA Kitchen cabinet & installer" bathroomTitle="RTA Bathroom cabinet & installer" countertopsTitle="Solid Surface Countertops" hoodsTitle="Kitchen Hoods" flooringTitle="LVP flooring" background="var(--bg-lighter)" />
            <section className="gallery">
                <div className="title">
                    <h2>Gallery</h2>
                    <hr />
                    <p>Check out our beautiful projects!</p>
                </div>
                <div className="content">
                    <div className="category">
                        <div className="category-name">
                            <h3>Face Frame and Euro Cabinets</h3>
                            <p className="primary">Same Price!</p>
                        </div>
                        <div className="items">
                            <Link to={"/face-frame-cabinets"}>
                                <Thumbnail item='face-framed' />
                            </Link>
                            <Link to={"/frameless-cabinets"}>
                                <Thumbnail item='euro-box' />
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            <Testimonials />
            <Footer />
        </div>
    )
}

export default Landing;