import './TextArea.css';

const TextArea = props => {
    return (
        <div className="TextArea">
            <textarea name={props.name} cols={props.cols} rows={props.rows} placeholder={props.placeholder} value={props.value} onChange={props.onChange}></textarea>
        </div>
    )
}

export default TextArea;