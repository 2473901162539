import "./index.css";
import Navbar from "../../compose/Navbar/Navbar";
import Footer from "../../compose/Footer/Footer"
import Contact from "../../compose/Contact/Contact";
import Testimonials from "compose/Testimonials/Testimonials";

import { Helmet } from 'react-helmet';

const ContactPage = () => {
    return (
        <>
            <Helmet>
                <title>Contact for Cabinets & Floor - Nampa, Boise | River City Cabinets</title>
                <meta name="description" content="Contact for cabinets or flooring in Nampa and Boise Area, Idaho. Our team is ready to bring your vision to life."></meta>
                <link rel="canonical" href="https://www.rivercitycabinetsidaho.com/contact/"></link>
            </Helmet>
            <Navbar />
            <Contact />
            <Testimonials />
            <Footer />
        </>
    )
}

export default ContactPage;