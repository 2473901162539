const data = [
    {
        author_name: "Clayton Trehal",
        author_url: "https://www.google.com/maps/contrib/115108550780732300929/photos/@43.8754875,-115.7039357,9z/data=!3m1!4b1!4m3!8m2!3m1!1e1?hl=en-US&entry=ttu",
        language: "en",
        profile_photo_url: "https://lh3.googleusercontent.com/a-/ALV-UjVNDaZrjzjCx4Tvx-6rZu1ew0OBX2TNunLl6a9GxZrwiCj1=w60-h60-p-rp-mo-br100",
        rating: 5,
        relative_time_description: "",
        text: "Sergey and his crew are the best. The cabinets are awesome, workmanship and service was awesome, and the whole experience was really good. Sergey does a great job making you feel taken care of during the process too. River City absolutely deserves their great reviews and I would recommend them to anyone. Thanks for the great job guys!",
        time: 1662131429
    },
    {
        author_name: "Julie Rising",
        author_url: "https://www.google.com/maps/contrib/104890296725046163300/photos/@43.60776,-116.610927,17z/data=!4m3!8m2!3m1!1e1?hl=en-US",
        language: "en",
        profile_photo_url: "https://lh3.googleusercontent.com/a-/ACNPEu9wbRo3wC23ckn7b-As47xqXSO2dDdYD93xUj3F2g=w120-h120-p-rp-mo-br100",
        rating: 5,
        relative_time_description: "",
        text: "River City Cabinets were great to work with!  Sergey was able to help create extra storage and add more countertop space in my kitchen and bathroom.  Installing the cabinets, counter tops, and under cabinet lightening were completed on-time.  The final results exceeded my expectations.  I highly recommend them!",
        time: 1662131429
    },
    {
        author_name: "Yelena Ludan",
        author_url: "https://www.google.com/maps/contrib/113289014592875841136/reviews/@43.5985863,-116.4370248,12z/data=!3m1!4b1!4m3!8m2!3m1!1e1?hl=en-US",
        language: "en",
        profile_photo_url: "https://lh3.googleusercontent.com/a-/ACNPEu8KvHLIi53ukAoxrhnTAEvYuGHY3r-K6l4BHWLe=w120-h120-p-rp-mo-br100",
        rating: 5,
        relative_time_description: "",
        text: "Absolutely happy customer! Five stars. Remodeled our kitchen and your master bath. My kitchen is actually way prettier then I planned and imagined in my mind. Sergey and his team were always here exactly the time they said they would be. Every time they left, they would clean up after. There was no surprises in pricing or product. Amazing with communication, super friendly. Highly recommend!",
        time: 1662131429
    },
    {
        author_name: "N8 gixx6 Smith",
        author_url: "https://www.google.com/maps/contrib/110386588165008149660/reviews/@43.6231234,-116.733601,10z/data=!3m1!4b1!4m3!8m2!3m1!1e1?hl=en-US",
        language: "en",
        profile_photo_url: "https://lh3.googleusercontent.com/a-/ACNPEu-kdhXlFA7hWeT2Uz2OteSBB5Sh0cNCzChjimP5aA=w120-h120-p-rp-mo-br100",
        rating: 5,
        relative_time_description: "",
        text: "I deliver for these guys, I get to see a lot of their amazing work and they really take pride in the cabinets they build! Ben and Sergio are great guys too, always friendly and always willing to jump in and help when needed. Definitely 5 star rating!",
        time: 1662131429
    },
    {
        author_name: "ALFARO FAMILY",
        author_url: "https://www.google.com/maps/contrib/104833687962018283822/reviews/@43.60776,-116.610927,17z/data=!3m1!4b1!4m3!8m2!3m1!1e1?hl=en-US",
        language: "en",
        profile_photo_url: "https://lh3.googleusercontent.com/a/ALm5wu3tr5A0U5WYGomJ3SjLl83-iuR9ZwAuo27neMS1=w120-h120-p-rp-mo-br100",
        rating: 5,
        relative_time_description: "",
        text: `I'll start off by saying that I don't usually post reviews. However, after working with River City Cabinets I can not let their work, customer service and product go without a stellar review. Anyone who knows me, knows that I am a perfectionist, and a researcher to no end!
        Our family feels so fortunate to have found them just before launching a kitchen and bath remodel that had been in the works for years. From the moment we requested a quote to the very last visit they made to our home it was all so professional and exceeded all of our expectations. Ben and Sergey are the absolute best! You will be so impressed with Ben's ability to translate your design wishes even after you change your mind or add new things along the way! And he did this without ever making you feel like you were a pest! And Sergey is also absolutely awesome, he was very knowledgeable and always did everything he said he would do without fail. He was super fair with pricing and we could not be happier with the end result of the final installation of cabinets and lighting. I would recommend this company to anyone wanting to remodel their kitchen, bathroom or anywhere you need cabinetry. They also remodeled my nephew's kitchen and he too had a fantastic experience!`,
        time: 1662131429
    },
    {
        author_name: "Julie Norris",
        author_url: "https://www.google.com/maps/contrib/105324802395421456819/reviews/@45.4819854,-119.2353916,7z/data=!3m1!4b1!4m3!8m2!3m1!1e1?hl=en-US",
        language: "en",
        profile_photo_url: "https://lh3.googleusercontent.com/a-/ACNPEu971ledImuBbawacYXfnL-374Lb_JYU20Llnq-zMA=w120-h120-p-rp-mo-ba3-br100",
        rating: 5,
        relative_time_description: "",
        text: "I am confident that I have the world's most beautiful kitchen.  I cannot express enough how wonderful the brothers are at River City cabinets. They were the last of the 4 companies that I interviewed for the job and were FIRST in every other way. Their price, attention to detail, communication, trustworthiness, craftsmanship, and kindness were better than I even thought to expect.  THANK YOU!",
        time: 1662131429
    },
    {
        author_name: "Pavel Kalinovskiy (Quality Flooring LLC)",
        author_url: "https://www.google.com/maps/contrib/106180294191639765288/reviews/@42.1907992,-114.316193,8z/data=!3m1!4b1!4m3!8m2!3m1!1e1?hl=en-US",
        language: "en",
        profile_photo_url: "https://lh3.googleusercontent.com/a-/ACNPEu9rCzVIUljTxnR0koLpSHLUZe7CZG40rjZL297PBg=w120-h120-p-rp-mo-br100",
        rating: 5,
        relative_time_description: "",
        text: `Proud to work for River City Cabinets, great quality, customer service and partnership.
        I would definitely highly recommend River City Cabinets for anyone who would want to redesign, update/remodel their home, these guys can do it all!`,
        time: 1662131429
    },
    {
        author_name: "Vanessa Brothers",
        author_url: "https://www.google.com/maps/contrib/110725941177347693692/reviews/@43.6394787,-116.4139016,11z/data=!3m1!4b1!4m3!8m2!3m1!1e1?hl=en-US",
        language: "en",
        profile_photo_url: "https://lh3.googleusercontent.com/a-/ACNPEu9gIkm4SInERZR6lu6B6oXCXFpY1KuThJVcWTPGFq8=w120-h120-p-rp-mo-br100",
        rating: 5,
        relative_time_description: "",
        text: `Beautiful cabinets! Highly satisfied! Great price!  After shopping at local "box stores" and coming away discouraged, disgusted, and frustrated, I heard about River City! They turned out to be exactly what we were looking for! We highly recommend River City Cabinets! They are highly knowledgeable, friendly, high quality cabinets, and a wonderful experience! ☺`,
        time: 1662131429
    },
    {
        author_name: "Ben Winkler",
        author_url: "https://www.google.com/maps/contrib/100295571712661474489/reviews/@43.5955521,-116.4865927,12z/data=!3m1!4b1!4m3!8m2!3m1!1e1?hl=en-US",
        language: "en",
        profile_photo_url: "https://lh3.googleusercontent.com/a/ALm5wu2hN8Pk9Sew_1oZ-_6nliVP-YcZizGrWRtci-5n=w120-h120-p-rp-mo-br100",
        rating: 5,
        relative_time_description: "",
        text: `I will definitely be recommending River City Cabinets. Ben and Serge were well versed in their product, and easy to work with. From picking cabinets to installation was a fast turnaround. I couldn't be happier`,
        time: 1662131429
    },
    {
        author_name: "quadence h",
        author_url: "https://www.google.com/maps/contrib/116365311389450614354/reviews/@43.60776,-116.610927,17z/data=!3m1!4b1!4m3!8m2!3m1!1e1?hl=en-US",
        language: "en",
        profile_photo_url: "https://lh3.googleusercontent.com/a-/ACNPEu9Wxe9N8N3q3Fe1INit8uvYEFCke1dAck2QU4yX=w120-h120-p-rp-mo-br100",
        rating: 5,
        relative_time_description: "",
        text: `I highly recommend River City Cabinets!  I was wanting to fill the space between the ceiling and the cabinets in my kitchen.  After spending time googling images and trying to figure out how to make my vision possible, I got in touch with River City Cabinets.  After I saw the finished product, I was blown away at how fantastic it turned out!`,
        time: 1662131429
    },
    {
        author_name: "Blake Holmes",
        author_url: "https://www.google.com/maps/contrib/108912142428673909060/reviews/@43.5902992,-116.4079514,11z/data=!3m1!4b1!4m3!8m2!3m1!1e1?hl=en-US",
        language: "en",
        profile_photo_url: "https://lh3.googleusercontent.com/a/ALm5wu3NlzQ6239UJTO4jqKjla0femU394PT5-acybgU=w120-h120-p-rp-mo-br100",
        rating: 5,
        relative_time_description: "",
        text: `I used these guys for a renovation. Best cabinets guys in town by far! from ordering to delivery. 5 stars.
        Will be using them in the future for flips and other projects.`,
        time: 1662131429
    },
    {
        author_name: "Sergey Stadnitsky",
        author_url: "https://www.google.com/maps/contrib/113052719681054500165/reviews/@43.6469671,-116.4752384,11z/data=!3m1!4b1!4m3!8m2!3m1!1e1?hl=en-US",
        language: "en",
        profile_photo_url: "https://lh3.googleusercontent.com/a-/ACNPEu99lC1SkwCd5kNzq_R03DXEmY2gXu9nRKuUIqFC=w120-h120-p-rp-mo-br100",
        rating: 5,
        relative_time_description: "",
        text: `Superior job by River City Cabinets. They build custom cabinets in my living room. Very satisfied!`,
        time: 1662131429
    },
    {
        author_name: "Tina Salisbury",
        author_url: "https://www.google.com/maps/contrib/109509680669610485029/reviews/@43.6276825,-116.4508985,12z/data=!3m1!4b1!4m3!8m2!3m1!1e1?hl=en-US",
        language: "en",
        profile_photo_url: "https://lh3.googleusercontent.com/a/ALm5wu2Hcl3heMvHJdL0Hvy5ZxfkLjX9T1BOzNjP_Xpq=w120-h120-p-rp-mo-br100",
        rating: 5,
        relative_time_description: "",
        text: `Excellent to work with from design to install!  Worked with us on our timeline and paid attention to every detail leaving us with a fabulous new bathroom!`,
        time: 1662131429
    },
    {
        author_name: "Mark MacKenzie",
        author_url: "https://www.google.com/maps/contrib/105232628852318843603/reviews/@41.2364563,-109.4653915,5z/data=!3m1!4b1!4m3!8m2!3m1!1e1?hl=en-US",
        language: "en",
        profile_photo_url: "https://lh3.googleusercontent.com/a-/ACNPEu-rOqdCZhA3AkkUQikCBb_MxWqk9LwiT_cVv357=w120-h120-p-rp-mo-br100",
        rating: 5,
        relative_time_description: "",
        text: `Can’t say enough bout Sergei and his work! Welcome to come see my house!`,
        time: 1662131429
    },
    {
        author_name: "Alla Wise",
        author_url: "https://www.google.com/maps/contrib/102060329022970039572/reviews/@43.6508994,-116.4822077,12z/data=!3m1!4b1!4m3!8m2!3m1!1e1?hl=en-US",
        language: "en",
        profile_photo_url: "https://lh3.googleusercontent.com/a/ALm5wu28G-nxZI7H7_gMPXEqtuihD68FeiX5JWEu7hYe=w120-h120-p-rp-mo-br100",
        rating: 5,
        relative_time_description: "",
        text: `Amazing service!!! Extremely knowledgeable and friendly! Ordered and received cabinets in 2 weeks! Great experience overall!`,
        time: 1662131429
    },
    {
        author_name: "Bogdan",
        author_url: "https://www.google.com/maps/contrib/111028595365474677619/reviews/@38.6920012,-106.1776206,6z/data=!3m1!4b1!4m3!8m2!3m1!1e1?hl=en-US",
        language: "en",
        profile_photo_url: "https://lh3.googleusercontent.com/a-/ACNPEu97r_VoVpwU7E0Tj09C0P0_hV_adyudJdgeIjBw=w120-h120-p-rp-mo-br100",
        rating: 5,
        relative_time_description: "",
        text: `Great service and top quality product.`,
        time: 1662131429
    },
    {
        author_name: "Sam Crow",
        author_url: "https://www.google.com/maps/contrib/110107982937719209880/reviews/@44.501917,-116.6866912,8z/data=!3m1!4b1!4m3!8m2!3m1!1e1?hl=en-US",
        language: "en",
        profile_photo_url: "https://lh3.googleusercontent.com/a/AGNmyxYxQgvoz0BScBN3nCZWTe6w1WzapSSI1z2umhFf=w120-h120-p-rp-mo-br100",
        rating: 5,
        relative_time_description: "",
        text: `We looked at all the cabinet shops in the area no one could get the cabinets that we liked in a timely manner. Sergey came out to our Farmhouse measured and had a computer diagram of our kitchen done. He gave us a delivery date when we placed our order and delivered on time. Cabinet and counter tops installed in a clean and timely manner! We love our new Kitchen Thanks to River City Cabinets.`,
        time: 1662131429
    },
    {
        author_name: "Ian Braly",
        author_url: "https://www.google.com/maps/contrib/109273460434985105820/photos/@43.60776,-116.610927,17z/data=!3m1!4b1!4m3!8m2!3m1!1e1?hl=en-US&entry=ttu",
        language: "en",
        profile_photo_url: "https://lh3.googleusercontent.com/a-/AD_cMMSSFC4Xu0qRZ02l88LNexWF2rvIZd2kYLr08Q4H_koG_yQ=w60-h60-p-rp-mo-br100",
        rating: 5,
        relative_time_description: "",
        text: `We are very happy with our cabinets from River City. The price point was very good considering the quality of the construction. They delivered ahead of schedule, which was really appreciated. Additionally, one of our rev-a-shelf drawers had a small issue and Serge came out to make the repair himself.`,
        time: 1662131429
    },
    {
        author_name: "Staci Rotarius",
        author_url: "https://www.google.com/maps/contrib/118017219085468480796/photos/@43.6140749,-116.6112189,11z/data=!4m3!8m2!3m1!1e1?hl=en-US&entry=ttu",
        language: "en",
        profile_photo_url: "https://lh3.googleusercontent.com/a/AAcHTtdG1lvz9A1kcwDcLJTFJVSPzmga3Msfe2nhItSVyTpj=w60-h60-p-rp-mo-br100",
        rating: 5,
        relative_time_description: "",
        text: `Sergey was the consummate professional. Design crew was amazing at interpreting my vision and turning it into reality. Very skilled at keeping the schedule on time (early in fact), and project under budget… Seriously, who does that anymore?! Would immediately hire this crew again!`,
        time: 1662131429
    }
    // {
    //     author_name: "Gregory Burlo",
    //     author_url: "https://www.google.com/maps/contrib/108068953152847694928/reviews/@43.6237978,-116.3333719,12z/data=!3m1!4b1!4m3!8m2!3m1!1e1?hl=en-US",
    //     language: "en",
    //     profile_photo_url: "https://lh3.googleusercontent.com/a-/ACNPEu-ZCgwdrlGQ86aPKYyBDiA9Y002EUMLoYQ-ZpUbeA=w120-h120-p-rp-mo-br100",
    //     rating: 5,
    //     relative_time_description: "",
    //     text: "",
    //     time: 1662131429
    // }
];

export default data;