import "./Heading.css";

const Heading = () => {
    return (
        <div className="heading">
            <h1>Explore the latest trends in cabinet design and renovation</h1>
        </div>
    )
}

export default Heading;