import "./Services.css";

import Navbar from '../../compose/Navbar/Navbar';
import Footer from '../../compose/Footer/Footer'
import Thumbnail from "../../components/Gallery/Thumbnail";
import Testimonials from "compose/Testimonials/Testimonials";

import { useNavigate } from 'react-router-dom';

import { Helmet } from 'react-helmet';

const Flooring = () => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate("/contact");
    }

    return (
        <>
            <Helmet>
                <title>LVP flooring in Nampa Idaho | River City Cabinets</title>
                <meta name="description" content="Revamp your home with River City Cabinets. We offer LVP flooring services. Contact us now!"></meta>
                <link rel="canonical" href="https://www.rivercitycabinetsidaho.com/services/flooring/"></link>
            </Helmet>
            <Navbar />
            <section className="services-pages">
                <div className="title">
                    <h1>LVP flooring service in Nampa, Boise Area</h1>
                    <hr />
                    <p>Transform Your Floors with River City Cabinets: Quality LVP Flooring Services in Nampa & Boise Area.</p>
                </div>
                <div className="item">
                    <Thumbnail item="flooring" cover noCursor />
                    <div className="info">
                        <h2>LVP flooring</h2>
                        <p>River City Cabinets are skilled in all forms of installation necessary to complete your flooring project. Depending upon the type of material you choose for your floors, there are several installation methods that can be used.</p>
                
                        <p>Some installations can be done over the top of your existing floor while others require a removal and haul away of existing flooring to reveal either a concrete or wood sub-floor. Luxury vinyl plank flooring looks so much like real hardwood flooring that even experienced contractors have a hard time telling the difference.</p>
                        
                        <p>Did we mention that it's much more affordable than hardwood? You can count on us to install luxury vinyl plank flooring throughout your home to enhance its appearance.</p>

                        <div className="cta">
                            <button className="primary-button" onClick={handleButtonClick}>Contact Now</button>
                        </div>
                    </div>
                </div>
            </section>
            <Testimonials />
            <Footer />
        </>
    )
}

export default Flooring;