import "./index.css";

import Navbar from '../../compose/Navbar/Navbar';
import Footer from '../../compose/Footer/Footer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

import SignatureCanvas from "react-signature-canvas";

import { useState, useRef } from "react";
import { jsPDF } from "jspdf";

import { Helmet } from 'react-helmet';

const AgreementPage = () => {
    const [initial1, setInitial1] = useState("");
    const [initial2, setInitial2] = useState("");
    const [initial3, setInitial3] = useState("");
    const [initial4, setInitial4] = useState("");
    const [initial5, setInitial5] = useState("");
    const [initial6, setInitial6] = useState("");
    const [initial7, setInitial7] = useState("");
    const [initial8, setInitial8] = useState("");
    const [initial9, setInitial9] = useState("");
    const [initial10, setInitial10] = useState("");
    const [initial11, setInitial11] = useState("");
    const [initial12, setInitial12] = useState("");
    const [initial13, setInitial13] = useState("");
    const [initial14, setInitial14] = useState("");
    const [cabinetName, setCabinetName] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [loadingMessage, setLoadingMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [verificationMessage, setVerificationMessage] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);

    const refInitial1 = useRef();
    const refInitial2 = useRef();
    const refInitial3 = useRef();
    const refInitial4 = useRef();
    const refInitial5 = useRef();
    const refInitial6 = useRef();
    const refInitial7 = useRef();
    const refInitial8 = useRef();
    const refInitial9 = useRef();
    const refInitial10= useRef();
    const refInitial11 = useRef();
    const refInitial12 = useRef();
    const refInitial13 = useRef();
    const refInitial14 = useRef();
    const refCabinetName = useRef();
    const refCustomerName = useRef();
    const refSigCanvasWrapper = useRef();
    const refSubmitButton = useRef();

    // const [token, setToken] = useState(null);

    // const handleLoaded = _ => {
    //     window.grecaptcha.ready(_ => {
    //         window.grecaptcha
    //             .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: "contract" })
    //             .then(tokenAux => {
    //                 setToken(tokenAux);
    //             })
    //     })
    // };

    // useEffect(() => {
    //     // Add reCAPTCHA
    //     const script = document.createElement("script");
    //     script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
    //     script.addEventListener("load", handleLoaded);
    //     document.body.appendChild(script);
    // }, []);

    const sigCanvas = useRef();
    const clearSignaturePad = (event) => {
        event.preventDefault();
        sigCanvas.current.clear();
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if(initial1 && initial2 && initial3 && initial4 && initial5 && initial6 && initial7 && initial8 && initial9 && initial10 && initial11 && initial12 && initial13 && initial14 && cabinetName && customerName && !sigCanvas.current.isEmpty()) {
            refSubmitButton.disabled = true;
            
            // messages
            setLoadingButton(true);
            setErrorMessage(false);
            setVerificationMessage(false);
            setLoadingMessage(true);

            refInitial1.current.style.border = "none";
            refInitial2.current.style.border = "none";
            refInitial3.current.style.border = "none";
            refInitial4.current.style.border = "none";
            refInitial5.current.style.border = "none";
            refInitial6.current.style.border = "none";
            refInitial7.current.style.border = "none";
            refInitial8.current.style.border = "none";
            refInitial9.current.style.border = "none";
            refInitial10.current.style.border = "none";
            refInitial11.current.style.border = "none";
            refInitial12.current.style.border = "none";
            refInitial13.current.style.border = "none";
            refInitial14.current.style.border = "none";
            refCabinetName.current.style.border = "none";
            refCustomerName.current.style.border = "none";
            refSigCanvasWrapper.current.style.border = "none";

            const doc = new jsPDF({
                format: "letter"
            })
    
            doc.setFontSize(12);
    
            doc.addPage();
            doc.addPage();

            doc.setPage(1);

            doc.text(`River City Cabinets - Contract - Custom Line
    
Customer's Name: ${customerName}`, 30, 20, {
    maxWidth: 150,
    align: 'left'
}
            );

            doc.text(`
    
1) Our pricing/quote applies to the items on your quote. It does not apply to any other items. If you make changes, additions, or subtractions, the price may change. We require a 50% down on cabinet material, upgraded material, hardware, taxes and 20% on labor to place your order. Remaining 50% of cabinet material and delivery paid when cabinetry is delivered. The balance, if any, must be paid after installation is complete. *Customer Initials: ${initial1}
    
2) We sell cabinets. If we do not install the cabinets, we are not responsible for anything that happens during installation. Your installer is responsible for that. Similarly, we are not responsible for “issues” with your walls, flooring, plumbing, painting, electric or anything else at the place of install. For example, uneven walls and floors are common. Sometimes this requires scribe moldings or base shoes to cover up the flaws, these items will not hold up installation but may be necessary. Its often difficult to predict before installation if those things are needed.  *Customer Initials: ${initial2}
    
3) Price does not include hardware (pulls/knobs) unless identified on the final quote. Even if you purchase hardware and/or other accessories, they do not come installed. This greatly reduces damage during transport plus your installer will expect to take care of this. *Customer Initials: ${initial3}
    
4) Glass doors are available for most of our cabinet design for an additional charge, which will be determined once the final scope of the project is established and provided in the quote. *Customer Initials: ${initial4}
    
5) There are no returns on installed, damaged items or special-order items. *Customer Initials: ${initial5}
    
6) Normal delivery time is 6-8 WEEKS (business days). Although most jobs are ready for shipment in 2 weeks, there are factors outside of our control which require the additional lead time we quote. In a case where we have a defect or warranty item, the additional time allows us to resolve any unforeseen issues without impacting the customers scheduled installation. It also provides us time to react if there are any delays in shipment due to inventory issues with a supplier and/or weather-related issues. *Customer Initials: ${initial6}
    
7) We are not responsible for damage once any merchandise leaves our facility unless River City Cabinets is managing the delivery. Any damage done during transport, storage or installation is the responsibility of the appropriate party. *Customer Initials: ${initial7}
    
8) Storage: In the event cabinets are ready for shipment as scheduled and the customer is unable to accept delivery, storage fees may apply if River City Cabinets is unable to store the cabinets in its finished goods area without impacting other customers. The storage rate will be straight pass thru of any direct expense incurred by River City Cabinets as well as labor and hauling charges. *Customer Initials: ${initial8}`, 30, 30, {
    maxWidth: 150,
    align: 'left'
}
            );

            doc.setPage(2);

            doc.text(`9) Painted Cabinets: Please be aware that wood expands and contracts. This natural occurring process on painted cabinetry may create open joints in the painted finish. An open joint line is not considered a defect and it is widely accepted in the kitchen cabinetry industry. The finish will still protect the surface, and the structural integrity of the joint will not be affected. These issues are shared by every manufacturer of painted real wood cabinets and are not unique to our company.

Wood may expand and contract differently from cabinet to cabinet. Even joints within the same cabinet may behave differently. Some joined wood may not show any joint lines, other may be slightly visible, while others may display easily visible joint lines.

Joint lines also may occur due to the repeated impacts from normal everyday use.

An exposed open wood joint can be damaged from prolonged exposure to water or heavy moisture. Therefore, wipe off any excess moisture from cabinet surfaces as soon as possible. 

Discoloration: All Paints, especially lighter colors, are susceptible to discoloration due to airborne particles such as cooking, grilling, tobacco smoke, fireplaces, or candles. Prolonged exposure to natural or artificial light may alter the finish color. Moisture may also discolor the finish. Due to these factors, cabinets or accessories added later may not exactly match previously installed cabinetry. For the same reason, door samples or displays may not represent the exact color of cabinets you receive. *Customer Initials: ${initial9}
    
10 ) Wood: All wood species are a product of mother nature. Wood Types, wood grains, textures and colors vary depending on growing location and other environmental factors. Because of this, each tree is unique which creates the natural beauty of the real wood and the infinite variations in grain, texture, and color. Painted cabinets are option if the variation patterns are a concern.
    
This is especially true of hardwood and hardwood veneers; hardwoods are particularly noted for their variety in graining. It is the nature of hardwoods to have mineral streaks (light brown, dark brown, to black areas in the wood) and great variation in graining. Since the number of mineral streaks varies from board to board, you may notice differences between cabinets, doors, drawers or even on the same panel. Mineral streaks or unusual graining are characteristic of natural wood.
    
Due to these natural and environmental factors, you will understand why our company cannot be responsible for naturally occurring changes experienced by the wood in your cabinets. *Customer Initials: ${initial10}
    
Customer: Please initial if you consent to River City Cabinets taking before and after pictures of the job and for use of Owners testimonials in advertising materials in print and/or electronically. *Customer Initials: ${initial11}`, 30, 20, {
    maxWidth: 150,
    align: 'left'
}
            );

            var data = sigCanvas.current.toDataURL("image/png");

            doc.setPage(3);

            const d = new Date();
            const month = d.getMonth() + 1;
            const day = d.getDate();
            const year = d.getFullYear();

            doc.text(`**River City Cabinets does not offer financing of any kind. Payments must be made within 36 hours of receiving the invoice for payment. Warranty items such as warped doors or replacement parts, drawers, knobs, pulls and hardware does not hold payment. *Customer Initials: ${initial12}

5% Daily fee will be imposed on all balances due. *Customer Initials: ${initial13}

Payment structure: 50% OF BALANCE: ${initial14}
            
Return Policy:
RCC will refund or exchange products that we sold along with the original receipt (refundable only within 5 days after delivered purchase to RCC warehouse; Only Refund or exchange if manufacture defect or other reasons that must be management approved. No buyer remorse. We reserve the right to impose a 20% restocking fee on returned items. All merchandise must be picked up or delivered within 19 days or the order is waived and the goods will be returned for sale. Hair line cracks are not considered defects. Natural wood products are the product of nature and are subjects to variations in color, luster and movement. Displays may not represent the overall appearance of actual products. We do not accept return nor exchange of products after cutting and/ or installation. Customer understands the condition and agrees to terms. NO RETURNS ON CUSTOM BUILT MATERIAL.

Cabinet Name/Construction: ${cabinetName}
            
Customer's Printed Name: ${customerName}

Date: ${month}-${day}-${year}

Customer's Signature: `, 30, 20, {
    maxWidth: 150,
    align: 'left'
}
            );

            doc.addImage(data, "PNG", 30, 155);

            doc.text(`River City Cabinets Rep: _______________________________

Date: _____________`, 30, 230, {
    maxWidth: 150,
    align: 'left'
}
            );

            var pdfBase64 = doc.output("blob");

            var file = new File([pdfBase64], "uploaded_file.pdf", { type: "application/pdf", lastModified: Date.now() });

            const formData = new FormData();

            formData.append(
                "file",
                file
            )

            // formData.append("g-recaptcha-response", token);

            fetch(`https://getform.io/f/${process.env.REACT_APP_GETFORM_URL}`, {
                method: "POST",
                body: formData,
                headers: {
                    "Accept": "application/json",
                }
            })
            .then(response => {
                setSuccessMessage(true);
                setLoadingMessage(false);
                setLoadingButton(false);
                setInitial1("");
                setInitial2("");
                setInitial3("");
                setInitial4("");
                setInitial5("");
                setInitial6("");
                setInitial7("");
                setInitial8("");
                setInitial9("");
                setInitial10("");
                setInitial11("");
                setInitial12("");
                setInitial13("");
                setInitial14("");
                setCabinetName("");
                setCustomerName("");
                sigCanvas.current.clear();
            })
            .catch(error => {
                console.log(error);
                setErrorMessage(true);
                setLoadingMessage(false);
                refSubmitButton.disabled = false;
                setLoadingButton(false);
            })
        } else {
            setVerificationMessage(true);

            initial1 ? refInitial1.current.style.border = "none" : refInitial1.current.style.border = "1px solid var(--danger-color)";
            initial2 ? refInitial2.current.style.border = "none" : refInitial2.current.style.border = "1px solid var(--danger-color)";
            initial3 ? refInitial3.current.style.border = "none" : refInitial3.current.style.border = "1px solid var(--danger-color)";
            initial4 ? refInitial4.current.style.border = "none" : refInitial4.current.style.border = "1px solid var(--danger-color)";
            initial5 ? refInitial5.current.style.border = "none" : refInitial5.current.style.border = "1px solid var(--danger-color)";
            initial6 ? refInitial6.current.style.border = "none" : refInitial6.current.style.border = "1px solid var(--danger-color)";
            initial7 ? refInitial7.current.style.border = "none" : refInitial7.current.style.border = "1px solid var(--danger-color)";
            initial8 ? refInitial8.current.style.border = "none" : refInitial8.current.style.border = "1px solid var(--danger-color)";
            initial9 ? refInitial9.current.style.border = "none" : refInitial9.current.style.border = "1px solid var(--danger-color)";
            initial10 ? refInitial10.current.style.border = "none" : refInitial10.current.style.border = "1px solid var(--danger-color)";
            initial11 ? refInitial11.current.style.border = "none" : refInitial11.current.style.border = "1px solid var(--danger-color)";
            initial12 ? refInitial12.current.style.border = "none" : refInitial12.current.style.border = "1px solid var(--danger-color)";
            initial13 ? refInitial13.current.style.border = "none" : refInitial13.current.style.border = "1px solid var(--danger-color)";
            initial14 ? refInitial14.current.style.border = "none" : refInitial14.current.style.border = "1px solid var(--danger-color)";

            cabinetName ? refCabinetName.current.style.border = "none" : refCabinetName.current.style.border = "1px solid var(--danger-color)";
            customerName ? refCustomerName.current.style.border = "none" : refCustomerName.current.style.border = "1px solid var(--danger-color)";

            !sigCanvas.current.isEmpty() ? refSigCanvasWrapper.current.style.border = "none" : refSigCanvasWrapper.current.style.border = "3px solid var(--danger-color)";
        }
    }

    return (
        <>

            <Helmet>
                <title>Contract Custom Line | River City Cabinets</title>
                <meta name="description" content="Please use this form if you are in the process of becoming a customer for River City Cabinets Custom Line products."></meta>
                <link rel="canonical" href="https://www.rivercitycabinetsidaho.com/custom-line-contract/"></link>
            </Helmet>
            <Navbar />
            <section className="agreement" id="agreement">
                <div className="title">
                    <h1>Contract - Custom Line</h1>
                    <hr/>
                    <p>Please use this form if you are in the process of becoming a customer for River City Cabinets Custom Line products.</p>
                </div>
                <form id="form" encType="multipart/form-data" onSubmit={handleSubmit}>
                    <p>1) Our pricing/quote applies to the items on your quote. It does not apply to any other items. If you make changes, additions, or subtractions, the price may change. We require a 50% down on cabinet material, upgraded material, hardware, taxes and 20% on labor to place your order. Remaining 50% of cabinet material and delivery paid when cabinetry is delivered. The balance, if any, must be paid after installation is complete. <span className="required">* </span><input type="text" className="initials" placeholder="Your initials here" id="initial1" value={initial1} onChange={(event) => setInitial1(event.target.value)} ref={refInitial1}/></p>
                    
                    <p>2) We sell cabinets. If we do not install the cabinets, we are not responsible for anything that happens during installation. Your installer is responsible for that. Similarly, we are not responsible for “issues” with your walls, flooring, plumbing, painting, electric or anything else at the place of install. For example, uneven walls and floors are common. Sometimes this requires scribe moldings or base shoes to cover up the flaws, these items will not hold up installation but may be necessary. Its often difficult to predict before installation if those things are needed. <span className="required">* </span><input type="text" className="initials" placeholder="Your initials here" id="initial2" value={initial2} onChange={(event) => setInitial2(event.target.value)} ref={refInitial2}/></p>
                    
                    <p>3) Price does not include hardware (pulls/knobs) unless identified on the final quote. Even if you purchase hardware and/or other accessories, they do not come installed. This greatly reduces damage during transport plus your installer will expect to take care of this. <span className="required">* </span><input type="text" className="initials" placeholder="Your initials here" id="initial3" value={initial3} onChange={(event) => setInitial3(event.target.value)} ref={refInitial3}/></p>

                    <p>4) Glass doors are available for most of our cabinet design for an additional charge, which will be determined once the final scope of the project is established and provided in the quote. <span className="required">* </span><input type="text" className="initials" placeholder="Your initials here" id="initial4" value={initial4} onChange={(event) => setInitial4(event.target.value)} ref={refInitial4}/></p>

                    <p>5) There are no returns on installed, damaged items or special-order items. <span className="required">* </span><input type="text" className="initials" placeholder="Your initials here" id="initial5" value={initial5} onChange={(event) => setInitial5(event.target.value)} ref={refInitial5}/></p>

                    <p>6) Normal delivery time is 6-8 WEEKS (business days). Although most jobs are ready for shipment in 2 weeks, there are factors outside of our control which require the additional lead time we quote. In a case where we have a defect or warranty item, the additional time allows us to resolve any unforeseen issues without impacting the customers scheduled installation. It also provides us time to react if there are any delays in shipment due to inventory issues with a supplier and/or weather-related issues. <span className="required">* </span><input type="text" className="initials" placeholder="Your initials here" id="initial6" value={initial6} onChange={(event) => setInitial6(event.target.value)} ref={refInitial6}/></p>

                    <p>7) We are not responsible for damage once any merchandise leaves our facility unless River City Cabinets is managing the delivery. Any damage done during transport, storage or installation is the responsibility of the appropriate party. <span className="required">* </span><input type="text" className="initials" placeholder="Your initials here" id="initial7" value={initial7} onChange={(event) => setInitial7(event.target.value)} ref={refInitial7}/></p>

                    <p>8) Storage: In the event cabinets are ready for shipment as scheduled and the customer is unable to accept delivery, storage fees may apply if River City Cabinets is unable to store the cabinets in its finished goods area without impacting other customers. The storage rate will be straight pass thru of any direct expense incurred by River City Cabinets as well as labor and hauling charges. <span className="required">* </span><input type="text" className="initials" placeholder="Your initials here" id="initial8" value={initial8} onChange={(event) => setInitial8(event.target.value)} ref={refInitial8}/></p>

                    <p>9) Painted Cabinets: Please be aware that wood expands and contracts. This natural occurring process on painted cabinetry may create open joints in the painted finish. An open joint line is not considered a defect and it is widely accepted in the kitchen cabinetry industry. The finish will still protect the surface, and the structural integrity of the joint will not be affected. These issues are shared by every manufacturer of painted real wood cabinets and are not unique to our company.</p>

                    <p>Wood may expand and contract differently from cabinet to cabinet. Even joints within the same cabinet may behave differently. Some joined wood may not show any joint lines, other may be slightly visible, while others may display easily visible joint lines.</p>

                    <p>Joint lines also may occur due to the repeated impacts from normal everyday use.</p>

                    <p>An exposed open wood joint can be damaged from prolonged exposure to water or heavy moisture. Therefore, wipe off any excess moisture from cabinet surfaces as soon as possible.</p>

                    <p>Discoloration: All Paints, especially lighter colors, are susceptible to discoloration due to airborne particles such as cooking, grilling, tobacco smoke, fireplaces, or candles. Prolonged exposure to natural or artificial light may alter the finish color. Moisture may also discolor the finish. Due to these factors, cabinets or accessories added later may not exactly match previously installed cabinetry. For the same reason, door samples or displays may not represent the exact color of cabinets you receive. <span className="required">* </span><input type="text" className="initials" placeholder="Your initials here" id="initial9" value={initial9} onChange={(event) => setInitial9(event.target.value)} ref={refInitial9}/></p>

                    <p>10 ) Wood: All wood species are a product of mother nature. Wood Types, wood grains, textures and colors vary depending on growing location and other environmental factors. Because of this, each tree is unique which creates the natural beauty of the real wood and the infinite variations in grain, texture, and color. Painted cabinets are option if the variation patterns are a concern.</p>

                    <p>This is especially true of hardwood and hardwood veneers; hardwoods are particularly noted for their variety in graining. It is the nature of hardwoods to have mineral streaks (light brown, dark brown, to black areas in the wood) and great variation in graining. Since the number of mineral streaks varies from board to board, you may notice differences between cabinets, doors, drawers or even on the same panel. Mineral streaks or unusual graining are characteristic of natural wood.</p>

                    <p>Due to these natural and environmental factors, you will understand why our company cannot be responsible for naturally occurring changes experienced by the wood in your cabinets. <span className="required">* </span><input type="text" className="initials" placeholder="Your initials here" id="initial10" value={initial10} onChange={(event) => setInitial10(event.target.value)} ref={refInitial10}/></p>

                    <p>Customer: Please initial if you consent to River City Cabinets taking before and after pictures of the job and for use of Owners testimonials in advertising materials in print and/or electronically. <span className="required">* </span><input type="text" className="initials" placeholder="Your initials here" id="initial11" value={initial11} onChange={(event) => setInitial11(event.target.value)} ref={refInitial11}/></p>

                    <p>**River City Cabinets does not offer financing of any kind. Payments must be made within 36 hours of receiving the invoice for payment. Warranty items such as warped doors or replacement parts, drawers, knobs, pulls and hardware does not hold payment. <span className="required">* </span><input type="text" className="initials" placeholder="Your initials here" id="initial12" value={initial12} onChange={(event) => setInitial12(event.target.value)} ref={refInitial12}></input></p>

                    <p>5% Daily fee will be imposed on all balances due. <span className="required">* </span><input type="text" className="initials" placeholder="Your initials here" id="initial13" value={initial13} onChange={(event) => setInitial13(event.target.value)} ref={refInitial13}></input></p>
                    
                    <p>Payment structure: 50% OF BALANCE: <span className="required">* </span><input type="text" className="initials" placeholder="Your initials here" id="initial14" value={initial14} onChange={(event) => setInitial14(event.target.value)} ref={refInitial14}></input></p>

                    <div className="names-flex">
                        <p>Cabinet's Name/Construction: <span className="required">* </span><input type="text" className="cabinets-name" placeholder="e.g.: Blue Shaker Face Framed" id="cabinets-name" value={cabinetName} onChange={(event) => setCabinetName(event.target.value)} ref={refCabinetName}/></p>

                        <p>Customer's Printed Name: <span className="required">* </span><input type="text" className="customers-name" id="customers-name" placeholder="Your full name here" name="name" value={customerName} onChange={(event) => setCustomerName(event.target.value)} ref={refCustomerName}/></p>
                    </div>

                    <div>
                        <br></br>
                        <p>Return Policy:<br></br>
                            RCC will refund or exchange products that we sold along with the original receipt (refundable only within 5 days after delivered purchase to RCC warehouse; Only Refund or exchange if manufacture defect or other reasons that must be management approved. No buyer remorse. We reserve the right to impose a 20% restocking fee on returned items. All merchandise must be picked up or delivered within 19 days or the order is waived and the goods will be returned for sale. Hair line cracks are not considered defects. Natural wood products are the product of nature and are subjects to variations in color, luster and movement. Displays may not represent the overall appearance of actual products. We do not accept return nor exchange of products after cutting and/ or installation. Customer understands the condition and agrees to terms. NO RETURNS ON CUSTOM BUILT MATERIAL.</p>
                    </div>

                    <div className="signature-flex">
                        <label htmlFor="signature-pad"><p>Customer's Signature: <span className="required">* </span></p></label>
                        <div className="wrapper" ref={refSigCanvasWrapper}>
                            <SignatureCanvas 
                                penColor="black"
                                canvasProps={{ className: "signature-pad" }}
                                ref={sigCanvas}
                            />
                        </div>
                        <button id="clear" onClick={clearSignaturePad} className="clear-signature-pad">Clear</button>
                    </div>

                    <div
                        className="g-recaptcha"
                        data-sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                        data-size="invisible"
                    ></div>

                    <div>
                        {verificationMessage && <p id="verification">* Please, complete all the fields.</p>}
                    </div>
                    
                    {loadingMessage &&<p id="loading-msg">Loading...</p>}
                    {errorMessage &&<p id="error-msg">Oops... Something wrong happened. Please try again.</p>}
                    {successMessage && <p id="success-msg">Everything looks good! Thank you for working with us.</p>}
                    <div className="cta">
                        {loadingButton ? (
                                <button type="submit" className="primary-button disabled" id="submit" disabled>
                                    <FontAwesomeIcon icon={icon({name: 'spinner', style: 'solid'})} spin />
                                    Submit</button>
                            ) :
                            (            
                                <button type="submit" className="primary-button" id="submit" onClick={handleSubmit} ref={refSubmitButton}>Submit</button>
                        )}
                    </div>
                </form>
            </section>
            <Footer />
        </>
    )
}

export default AgreementPage;