// Pages
import Kitchen from "./pages/services/Kitchen";
import Bathroom from "./pages/services/Bathroom";
import Flooring from "./pages/services/Flooring";
import Countertops from "./pages/services/Countertops";
import Hoods from "./pages/services/Hoods";
import Services from "./pages/services/Services";
import Gallery from "./pages/gallery/";
import FaceFramed from "./pages/cabinet-styles/FaceFrame";
import EuroBox from "./pages/cabinet-styles/EuroBox";
import ContactPage from "./pages/contact/";
import AgreementPage from "./pages/agreement/";
import CustomContract from "./pages/custom-contract/";
import BlogPage from "pages/blog";
import ArticlePage from "pages/blog/article"

const routes = [
    {
        name: "services",
        collapse: [
            {
                name: "services",
                route: "/services",
                component: <Services />,
            },
            {
                name: "kitchen",
                route: "/services/kitchen",
                component: <Kitchen />,
            },
            {
                name: "bathroom",
                route: "/services/bathroom",
                component: <Bathroom />,
            },
            {
                name: "flooring",
                route: "/services/flooring",
                component: <Flooring />,
            },
            {
                name: "countertops",
                route: "/services/countertops",
                component: <Countertops />,
            },
            {
                name: "hoods",
                route: "/services/kitchen-hoods",
                component: <Hoods />,
            }
        ]
    },
    {
        name: "gallery",
        collapse: [
            {
                route: "/gallery",
                component: <Gallery />,
            }
        ]
    },
    {
        name: "face-framed",
        collapse: [
            {
                route: "/face-frame-cabinets",
                component: <FaceFramed />,
            }
        ]
    },
    {
        name: "euro-box",
        collapse: [
            {
                route: "/frameless-cabinets",
                component: <EuroBox />,
            }
        ]
    },
    {
        name: "contact",
        collapse: [
            {
                route: "/contact",
                component: <ContactPage />
            }
        ]
    },
    {
        name: "agreement",
        collapse: [
            {
                route: "/agreement",
                component: <AgreementPage />
            },
            {
                route: "/custom-line-contract",
                component: <CustomContract />
            }
        ]
    },
    {
        name: "blog",
        collapse: [
            {
                route: "/blog",
                component: <BlogPage />
            }
        ]
    },
    {
        name: "article",
        collapse: [
            {
                route: "/blog/:id",
                component: <ArticlePage />
            }
        ]
    }
]

export default routes;