import './Navbar.css';

import { useRef } from "react";

import Logo from './imgs/rcc.png';
// import SearchBar from '../../components/Search/SearchBar';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Link } from 'react-router-dom';

const NavBar = () => {
    const phoneElement = useRef(null);

    // const displayPhoneElement = (isVisible) => {
    //     if (isVisible) {
    //         phoneElement.current.style.display = "none";
    //     } else {
    //         phoneElement.current.style.display = "flex";
    //     }
    // }

    window.addEventListener('scroll', function() {
        const header = document.querySelector('header');
        header.classList.toggle("sticky", window.scrollY > 0);
    
        const logo = document.querySelector('.logo');
        logo.classList.toggle("sticky", window.scrollY > 0);
    })
    
    function toggleMenu() {
        const menuToggle = document.querySelector('.menuToggle');
        const navigation = document.querySelector('.navigation');
        menuToggle.classList.toggle('active');
        navigation.classList.toggle('active');
    }

    return (
        <header>
            <Link to="/" className="logo">
                <img src={ Logo } alt="logo" id="logo" />
            </Link>
            <div className="phone" ref={phoneElement}>
                <a href="tel:2085595302"><i><FontAwesomeIcon icon={icon({name: 'phone', style: 'solid'})} className='icon' /></i></a>
                <a href="tel:2085595302" className="text">Call Now</a>
            </div>
            <div className="social">
                <a href="https://instagram.com/rivercitycabinets/" target="_blank" rel="noreferrer"><i><FontAwesomeIcon icon={icon({name: 'instagram', style: 'brands'})} className='icon' /></i></a>
                <a href="https://www.facebook.com/rcc208" target="_blank" rel="noreferrer"><i><FontAwesomeIcon icon={icon({name: 'facebook', style: 'brands'})} className='icon' /></i></a>
                <a href="https://www.pinterest.com/rivercitycabinets/" target="_blank" rel="noreferrer"><i><FontAwesomeIcon icon={icon({name: 'pinterest', style: 'brands'})} className='icon' /></i></a>
            </div>
            {/* <SearchBar callback={displayPhoneElement} /> */}
            <div className="menuToggle" onClick={ toggleMenu }>
                <i><FontAwesomeIcon icon={icon({name: 'bars', style: 'solid'})} className='icon' /></i>
            </div>
            <ul className="navigation">
                <li onClick={ toggleMenu }><Link to={"/"}>Home</Link></li>
                <li onClick={ toggleMenu }><Link to={"/services"}>Services</Link></li>
                <li onClick={ toggleMenu }><Link to={"/gallery"}>Gallery</Link></li>
                <li onClick={ toggleMenu }><Link to={"/contact"}>Contact</Link></li>
                <li onClick={ toggleMenu }><Link to={"/blog"}>Blog</Link></li>
            </ul>
        </header>
    )
}

export default NavBar;