import "./index.css";

import Navbar from 'compose/Navbar/Navbar';
import Footer from 'compose/Footer/Footer';

import { useNavigate } from "react-router-dom";

import { Helmet } from 'react-helmet';

const PageNotFound = () => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate("/");
    }

    return (
        <>
            <Helmet>
                <title>404 Page Not Found | River City Cabinets</title>
            </Helmet>
            <Navbar />
            <div className="page-not-found">
                <div className="content">
                    <div className="text">
                        <div className="title">
                            <hr />
                            <h1>404. Sorry, the page you're trying to find does not exist.</h1>
                        </div>
                        <div className="subtitle">
                            <h2 className="primary">If you need help trying to find something, please contact us.</h2>
                        </div>
                        <div className="button-banner-container">
                            <button className="primary-button" onClick={handleButtonClick} >Go Home</button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default PageNotFound;