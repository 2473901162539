import "./Services.css";

import Navbar from '../../compose/Navbar/Navbar';
import Footer from '../../compose/Footer/Footer'
import Thumbnail from "../../components/Gallery/Thumbnail";
import Testimonials from "compose/Testimonials/Testimonials";

import Image from "assets/imgs/gallery/torrance-white/2.webp";

import { useNavigate } from 'react-router-dom';

import { Helmet } from 'react-helmet';

const Countertops = () => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate("/contact");
    }

    return (
        <>
            <Helmet>
                <title>Solid Surface Countertops | River City Cabinets</title>
                <meta name="description" content="Solid surface countertops and more. Get a free quote right now!"></meta>
                <link rel="canonical" href="https://www.rivercitycabinetsidaho.com/services/countertops/"></link>
            </Helmet>
            <Navbar />
            <section className="services-pages">
                <div className="title">
                    <h1>Transform your space with premium solid surface countertops</h1>
                    <hr />
                    <p>Upgrade your kitchen or bathroom with durable, stylish solid surface countertops from River City Cabinets.</p>
                </div>
                <div className="item">
                    <Thumbnail item="countertops" cover noCursor />
                    <div className="info">
                        <h2>What is solid surface countertops?</h2>
                        <p>
                            Solid surface countertops offer all the benefits of a high-end kitchen or bathroom, without the cost or maintenance of natural stone. These durable yet stylish countertops are made from a blend of acrylic, polyester resins, and natural minerals, making them resistant to scratches, stains, and heat. The seamless installation process creates a flow in your space, and with an array of colors and patterns to choose from, you will never have to worry about compromising on style and design.
                        </p>
                        <p>
                            Unlike natural stone surfaces, solid surface countertops are non-porous, meaning they won't harbor bacteria or mold, making them hygienic and easy to maintain. And with the option to incorporate your sink and backsplash into the countertop, you can get a truly integrated look.
                        </p>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <img src={Image} alt="Kitchen Cabinet with a solid surface countertop." style={{width: "100%", maxWidth: "640px"}}/>
                        </div>
                        <p>
                            Sound too good to be true? We at River City Cabinets offer a high level of customer service and guarantee a flawless finish, so you can rest assured you're covered from start to finish.
                        </p>
                        <p>
                            With solid surface countertops, you get all the benefits of a high-end kitchen or bathroom at a fraction of the cost. Upgrade your space today with solid surface countertops to add style, character, and long-lasting appeal.
                        </p>
                        <div className="cta">
                            <button className="primary-button" onClick={handleButtonClick}>Contact Now</button>
                        </div>
                    </div>
                </div>
            </section>
            <Testimonials />
            <Footer />
        </>
    )
}

export default Countertops;