import "./Services.css";

import Navbar from '../../compose/Navbar/Navbar';
import Footer from '../../compose/Footer/Footer'
import Thumbnail from "../../components/Gallery/Thumbnail";
import Testimonials from "compose/Testimonials/Testimonials";

import Image from "assets/imgs/services/kitchenpage.webp";

import { useNavigate } from 'react-router-dom';

import { Helmet } from 'react-helmet';

const Kitchen = () => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate("/contact");
    }

    return (
        <>
            <Helmet>
                <title>High End RTA Kitchen Cabinets | River City Cabinets</title>
                <meta name="description" content="Learn more about our high end kitchen cabinets and get a free quote right now for you home or commercial cabinets."></meta>
                <link rel="canonical" href="https://www.rivercitycabinetsidaho.com/services/kitchen/"></link>
            </Helmet>
            <Navbar />
            <section className="services-pages">
                <div className="title">
                    <h1>High end kitchen cabinets</h1>
                    <hr />
                    <p>Get the Quality and Style You Deserve with River City Cabinets.</p>
                </div>
                <div className="item">
                    <Thumbnail item="kitchen" cover noCursor />
                    <div className="info">
                        <h2>Top kitchen cabinets</h2>
                        <p>
                            River City Cabinets is your premier kitchen cabinet shop, offering top-of-the-line, high-end kitchen cabinets that are both stylish and functional. Our expert craftsmen are experienced in creating a wide variety of kitchen cabinets, using modern light wood cabinets and more. We use only the highest quality materials to ensure that your cabinets are both beautiful and long-lasting.
                        </p>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <img src={Image} alt="Kitchen cabinet professional working." style={{width: "100%", maxWidth: "640px"}}/>
                        </div>
                        <p>
                            We offer ready-to-assemble kitchen cabinetry, or RTA kitchen cabinets, which are easy to assemble and install without sacrificing quality. We have the expertise and experience to deliver cabinets that meet your needs and exceed your expectations.
                        </p>
                        <p>
                            At River City Cabinets, we pride ourselves on our exceptional service, attention to detail, and commitment to quality. From the initial consultation to the final installation, our team of professionals will work with you every step of the way to ensure that your kitchen cabinets are exactly what you envisioned. Trust River City Cabinets for all your kitchen cabinet needs.
                        </p>
                        <div className="cta">
                            <button className="primary-button" onClick={handleButtonClick}>Contact Now</button>
                        </div>
                    </div>
                </div>
            </section>
            <Testimonials />
            <Footer />
        </>
    )
}

export default Kitchen;