import "./index.css";

import Navbar from "compose/Navbar/Navbar";
import Footer from "compose/Footer/Footer";
import Heading from "./sections/Heading";
import Articles from "./sections/Articles";

import { Helmet } from 'react-helmet';

const BlogPage = () => {
    return (
        <div className="blog-page">
            <Helmet>
                <title>Latest Trends in Cabinet Design | River City Cabinets</title>
                <meta name="description" content="Explore the latest trends in cabinet design with our Blog. From kitchen to bathroom cabinets, we cover it all."></meta>
                <link rel="canonical" href="https://www.rivercitycabinetsidaho.com/blog/"></link>
            </Helmet>
            <Navbar />
            <Heading />
            <Articles />
            <Footer />
        </div>
    )
}

export default BlogPage;