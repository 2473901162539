import "./Services.css";

import Navbar from '../../compose/Navbar/Navbar';
import Footer from '../../compose/Footer/Footer'
import Thumbnail from "../../components/Gallery/Thumbnail";
import Testimonials from "compose/Testimonials/Testimonials";

import { useNavigate } from 'react-router-dom';

import { Helmet } from 'react-helmet';

const Countertops = () => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate("/contact");
    }

    return (
        <>
            <Helmet>
                <title>Kitchen Hoods | River City Cabinets</title>
                <meta name="description" content="Elevate your kitchen with stylish hoods. Explore sleek designs & powerful ventilation for premium cooking experiences. Shop now!"></meta>
                <link rel="canonical" href="https://www.rivercitycabinetsidaho.com/services/kitchen-hoods/"></link>
            </Helmet>
            <Navbar />
            <section className="services-pages">
                <div className="title">
                    <h1>Kitchen Hoods</h1>
                    <hr />
                    <p>Elevate your kitchen with stylish hoods. Explore sleek designs & powerful ventilation for premium cooking experiences. Shop now!</p>
                </div>
                <div className="item">
                    <Thumbnail item="hoods" cover noCursor />
                    <div className="info">
                        <h2>Elevate Your Kitchen with Innovative Hoods</h2>
                        <p>
                            Transform your cooking space effortlessly with our diverse range of kitchen hoods. Navigate through various sizes and styles, each accompanied by clear dimensions displayed on pictures for easy selection. From sleek modern designs to classic aesthetics, find the perfect match for your kitchen decor.
                        </p>
                        <div className="items hood">
                            <Thumbnail item='asheville-1' lightbox={true} />
                            <Thumbnail item='asheville-2' lightbox={true} />
                            <Thumbnail item='asheville-3' lightbox={true} />
                            <Thumbnail item='charlotte-1' lightbox={true} />
                            <Thumbnail item='charlotte-2' lightbox={true} />
                            <Thumbnail item='charlotte-3' lightbox={true} />
                            <Thumbnail item='raleigh-1' lightbox={true} />
                            <Thumbnail item='raleigh-2' lightbox={true} />
                            <Thumbnail item='raleigh-3' lightbox={true} />
                            <Thumbnail item='wilmington-1' lightbox={true} />
                            <Thumbnail item='wilmington-2' lightbox={true} />
                            <Thumbnail item='wilmington-3' lightbox={true} />
                            <Thumbnail item='wilmington-4' lightbox={true} />
                        </div>
                        <p>
                            Say goodbye to lingering odors and hello to fresh air with our powerful ventilation systems. Explore our collection now and take the first step towards a cleaner, more inviting kitchen environment.
                        </p>
                        <div className="cta">
                            <button className="primary-button" onClick={handleButtonClick}>Contact Now</button>
                        </div>
                    </div>
                </div>
            </section>
            <Testimonials />
            <Footer />
        </>
    )
}

export default Countertops;