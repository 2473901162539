import './InputBox.css';

const InputBox = props => {
    return (
        <div className='InputBox'>
            <input type={props.type} name={props.name} placeholder={props.placeholder} value={props.value} onChange={props.onChange} />
        </div>
    )
}

export default InputBox;