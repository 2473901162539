import './Contact.css';

import { useState } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

import InputBox from '../../components/Form/InputBox';
import TextArea from '../../components/Form/TextArea';
import FileInput from '../../components/Form/FileInput';

import { Link } from 'react-router-dom';

const Contact = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [files, setFiles] = useState([]);
    const [filesError, setFilesError] = useState(false);

    // handling errors
    const [nameError, setNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [phoneError, setPhoneError] = useState("");

    // loading message
    const [loadingButton, setLoadingButton] = useState(false);

    // success & fail message
    const [successMessage, setSuccessMessage] = useState(false);
    const [failMessage, setFailMessage] = useState(false);

    // const [token, setToken] = useState(null);

    // const handleLoaded = _ => {
    //     window.grecaptcha.ready(_ => {
    //         window.grecaptcha
    //             .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: "contact" })
    //             .then(tokenAux => {
    //                 setToken(tokenAux);
    //             })
    //     })
    // };

    // useEffect(() => {
    //     // Add reCAPTCHA
    //     const script = document.createElement("script");
    //     script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
    //     script.addEventListener("load", handleLoaded);
    //     document.body.appendChild(script);
    // }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();

        setSuccessMessage(false);
        setFailMessage(false);

        if(!name) {
            setNameError("* Name is required.");
        } else {
            setNameError("");
        }

        if(!email) {
            setEmailError("* Email is required.");
        } else {
            setEmailError("");
        }

        if(!phone) {
            setPhoneError("* Phone is required.");
        } else {
            setPhoneError("");
        }

        if (name && email && phone && !filesError) {
            setLoadingButton(true);
            
            const formData = new FormData(event.target);

            formData.append("name", name);
            formData.append("email", email);
            formData.append("phone", phone);

            if (message) {
                formData.append("message", message);
            }
    
            if (files.length > 0) {
                for (let i = 0; i < files.length; i++) {
                    formData.append(`file_${i}`, files[i]);
                }
            }
    
            // formData.append("g-recaptcha-response", token);
    
            // Make the HTTP POST request using fetch
            fetch(`https://getform.io/f/${process.env.REACT_APP_GETFORM_URL}`, {
                method: "POST",
                body: formData,
                headers: {
                    "Accept": "application/json",
                }
            })
            .then(response => {
                if (response.ok) {
                    setName("");
                    setEmail("");
                    setPhone("");
                    setMessage("");
                    setFiles([]);
                    setSuccessMessage(true);
                    setFailMessage(false);
                    setLoadingButton(false);
                } else {
                    setFailMessage(true);
                    setLoadingButton(false);
                    throw new Error("Form submission failed");
                }
            })
            .catch(error => {
                console.error("Error submitting form:", error);
                setFailMessage(true);
                setLoadingButton(false);
            })
        }
    };

    return (
        <section className="contact" id="contact">
            <div className="title">
                <h1>Contact for Cabinets in Nampa & Boise Area</h1>
                <hr />
                <p>Get quality cabinetry and flooring services from River City Cabinets.</p>
            </div>
            <div className="infos">
                <div className="info">
                    <Link to="mailto:rccsales208@gmail.com">
                        <FontAwesomeIcon icon={icon({name: 'envelope', style: 'solid'})} className='icon' />
                    </Link>
                    <h2><a href="mailto:rccsales208@gmail.com">rccsales208@gmail.com</a></h2>
                </div>
                <div className="info">
                    <Link to="tel:2085595302">
                        <FontAwesomeIcon icon={icon({name: 'phone', style: 'solid'})} className='icon' />
                    </Link>
                    <h2><a href="tel:2085595302">208-559-5302</a></h2>
                </div>
                <div className="info">
                    <Link to="https://g.page/rivercitycabinetsidaho?share" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={icon({name: 'map-pin', style: 'solid'})} className='icon' />
                    </Link>
                    <h2><a href="https://g.page/rivercitycabinetsidaho?share" target="_blank" rel="noreferrer">2603 Sundance Road, Suite 101, Nampa ID, 83651</a></h2>
                </div>
            </div>
            <form className="form" encType="multipart/form-data" onSubmit={handleSubmit}>
                <InputBox type='text' name='name' placeholder='Name' value={name} onChange={(event) => setName(event.target.value)} />
                <InputBox type='email' name='email' placeholder='Email' value={email} onChange={(event) => setEmail(event.target.value)} />
                <InputBox type='tel' name='phone' placeholder='Phone' value={phone} onChange={(event) => setPhone(event.target.value)} />
                <TextArea name="message" cols="30" rows="10" placeholder="Message" value={message} onChange={(event) => setMessage(event.target.value)} />

                {successMessage && <p className="success-msg">Everything looks good! Thank you for working with us.</p>}

                {failMessage && <p className="error-msg">Oops... Something wrong happened. Please try again.</p>}

                {nameError && <p className="error-msg">{nameError}</p>}
                {emailError && <p className="error-msg">{emailError}</p>}
                {phoneError && <p className="error-msg">{phoneError}</p>}

                {/* <div
                    className="g-recaptcha"
                    data-sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    data-size="invisible"
                ></div> */}
                <div className="action">
                    <div className="attach" id="attach">
                        <FileInput onChange={setFiles} errorState={setFilesError} />
                    </div>
                    {loadingButton ? (
                        <>
                            <button type="submit" 
                            className="primary-button disabled" disabled> 
                                <FontAwesomeIcon icon={icon({name: 'spinner', style: 'solid'})} spin />
                                Send
                            </button>
                        </>

                    ) :
                        <button type="submit" className="primary-button">Send</button>
                    }
                </div>
            </form>
        </section>
    )
}

export default Contact;